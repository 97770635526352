import { createMuiTheme } from '@material-ui/core/styles';
import { blueGrey, brown } from '@material-ui/core/colors';

export const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: blueGrey[200],
    },
    secondary: {
      main: brown[300],
    },
    background: {
      default: '#fff'
    }
  },
});
