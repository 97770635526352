import project1 from '../assets/projects-1.jpg';
import project2 from '../assets/projects-2.jpg';
import project3 from '../assets/projects-3.jpg';
import project4 from '../assets/projects-4.jpg';
import project5 from '../assets/projects-5.jpg';
import project6 from '../assets/projects-6.jpg';
import project7 from '../assets/projects-7.jpg';
import project9 from '../assets/projects-9.jpg';

const projectsData = [{
  image: project1,
  title: 'breath',
  path: '/breath',
}, {
  image: project2,
  title: 'drawing desk',
  path: '/drawing-desk',
}, {
  image: project3,
  title: 'duophonic drawing pod',
  path: '/duophonic-drawing-pod',
}, {
  image: project4,
  title: 'sound/system',
  path: '/sound-system',
}, {
  image: project5,
  title: 'magical mistakes',
  path: '/magical-mistakes',
}, {
  image: project6,
  title: 'patternicity 001',
  path: '/patternicity-001',
}, {
  image: project7,
  title: 'patternicity 002',
  path: '/patternicity-002',
}, {
  image: project9,
  title: 'riso',
  path: '/riso',
}];

export default projectsData;
