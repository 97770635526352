import React from 'react';
import { Link as RouterLink, useRouteMatch, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

function Page({ title, children }) {
  const { id } = useParams();
  const { url } = useRouteMatch();
  const isProject = url.split('/').includes('projects') && id !== undefined;

  return (
    <Container maxWidth="md" disableGutters fixed>
      {isProject && (
        <Link
          component={RouterLink}
          to="/projects"
          underline="none"
        >
          projects/
        </Link>
      )}
      <Typography variant="h4" gutterBottom>{title}</Typography>
      {children}
    </Container>
  );
}

export default Page;
