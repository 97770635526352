import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveEmbed from 'react-responsive-embed';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import image1 from '../assets/breath-1.jpg';
import image2 from '../assets/breath-2.jpg';

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: '100%',
  },
  quote: {
    margin: 0,
    color: theme.palette.text.secondary,
  },
  video: {
    minWidth: '100%',
    padding: theme.spacing(1.5),
  }
}));

const Breath = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item>
        <img className={classes.responsive} src={image1} alt="breath" />
      </Grid>
      <Grid item xs={12} sm={5}>
        <List disablePadding>
          <ListItem dense>
            <ListItemText
              primary="Contributing Artists"
              secondary={
                <>
                  Rob Brown<br />
                  Patrick Faulk
                </>
              }
            />
          </ListItem>
          <ListItem dense>
            <ListItemText primary="Year" secondary="2013" />
          </ListItem>
          <ListItem dense>
            <ListItemText
              primary="Medium"
              secondary="plywood, steel, electronics and software"
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} sm={7}>
        <blockquote className={classes.quote}>
          <p>“I relish the brief yet eternal moment, the point at which life unravels and I catch a glimpse of what was always present; like waking from a dream, or the blinking moment I occupy the space between words.</p>
          <p>It is the air of eternity much longed for, though presently untouched.</p>
          <p>Listening to breaths is just as relevant as the words they space. What would be considered the removal of content, then generates new content, and perhaps an honest expression of sound. With this piece a device is actively listening to several streaming talk radio programs. Using filters and decibel thresholds, the device records up to 30 seconds of non-speech. As the viewer sits a sensor is activated, whereupon sound is released into the space. Once the clip has played, it is deleted from the buffer and a new audio file regenerates in the empty space.</p>
          <p>I wanted a representation of what is heard, though potentially not listened to; a memorial to those sounds which pass unnoticed.”</p>
          <cite>-Patrick Faulk</cite>
        </blockquote>
      </Grid>
      <div className={classes.video}>
        <ResponsiveEmbed
          src="//www.youtube.com/embed/oU5S5YttxYw?rel=0&amp;modestbranding=1&amp;showinfo=0&amp;controls=0&amp;vq=large"
          allowFullScreen
        />
      </div>
      <Grid item>
        <img className={classes.responsive} src={image2} alt="breath" />
      </Grid>
    </Grid>
  )
}

export default Breath;
