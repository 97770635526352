import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import image1 from '../assets/drawing-desk-1.jpg';
import image2 from '../assets/drawing-desk-2.jpg';
import image3 from '../assets/drawing-desk-3.jpg';
import image4 from '../assets/drawing-desk-4.jpg';
import image5 from '../assets/drawing-desk-5.jpg';
import audio from '../assets/drawing-desk.mp3';

const images = [
  image1,
  image2,
  image3,
  image5,
];

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: '100%',
  },
}));

const DrawingDesk = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item>
        <img className={classes.responsive} src={image4} alt="drawing-desk" />
      </Grid>
      <Grid item xs={12}>
        <audio controls>
          <source
            src={audio}
            type="audio/mpeg"
          />
        </audio>
      </Grid>
      <Grid item xs={12} sm={5}>
        <List disablePadding>
          <ListItem dense>
            <ListItemText primary="Year" secondary="2013" />
          </ListItem>
          <ListItem dense>
            <ListItemText
              primary="Medium"
              secondary="plywood, electronics, software"
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} sm={7}>
        <p>
          Plywood drafting table styled desk with a
          piece of drawing paper and a pencil on the surface. The inside of
          the desk houses a contact microphone (placed under the drawing paper),
          a laptop computer running audio processing software and a pair of sound
          isolating headphones. The system records, alters and plays back the sounds
          the participants create through drawing or writing.
        </p>
      </Grid>

      {images.map((image, index) => (
        <Grid item key={index}>
          <img className={classes.responsive} src={image} alt="drawing-desk" />
        </Grid>
      ))}
    </Grid>
  )
}

export default DrawingDesk;
