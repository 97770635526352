import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Page from '../components/Page';
import projectsData from '../data/projectsData';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  full: {
    width: '100%',
    height: '100%'
  },
}));

const ProjectsMenu = () => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const matches = useMediaQuery('(max-width:600px)');

  return !!projectsData.length ? (
    <Page title="projects">
      <div className={classes.root}>
        <GridList cellHeight="auto" spacing={0}>
          {projectsData.map((tile) => (
            <GridListTile
              cols={matches ? 2 : 1}
              key={tile.image}
              classes={{ imgFullWidth: classes.full, imgFullHeight: classes.full }}
            >
              <img src={tile.image} alt={tile.title} />
              <GridListTileBar
                title={tile.title}
                actionIcon={
                  <IconButton
                    aria-label={`navigate to ${tile.title}`}
                    color="primary"
                    component={Link}
                    to={`${url}${tile.path}`}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
    </Page>
  ) : null;
}

export default ProjectsMenu;
