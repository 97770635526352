import image1 from '../assets/06-pads-grids.jpg';
import image2 from '../assets/07-cross-over.jpg';
import image3 from '../assets/08-field-sync.jpg';
import image4 from '../assets/09-nfz.jpg';
import image5 from '../assets/10-slide.jpg';
import image6 from '../assets/11-phase-distortion.jpg';
import image7 from '../assets/12-pulse-wave.jpg';
import image8 from '../assets/13-quantize.jpg';
import image9 from '../assets/14-sync-drift.jpg';
import image10 from '../assets/15-terminal.jpg';
import image11 from '../assets/16-video-logic.jpg';
import image12 from '../assets/17-zone.jpg';
import image13 from '../assets/18-tyd.jpg';
import image14 from '../assets/19-discontinuity.jpg';

const images = [{
    original: image1,
    originalTitle: "pads/grids",
    originalDescription: "acrylic and risograph on paper. 11\"x15\"",
}, {
    original: image2,
    originalTitle: "cross-over",
    originalDescription: "​acrylic watercolor and graphite on yupo. 10.5\"x8.25\"",
}, {
    original: image3,
    originalTitle: "field sync",
    originalDescription: "​acrylic, gouache and toner on paper. 11\"x15\"",
}, {
    original: image4,
    originalTitle: "nfz",
    originalDescription: "​colored pencil and gouache on paper. 6.25\"x5\"",
}, {
    original: image5,
    originalTitle: "slid",
    originalDescription: "gouache and graphite on paper. 8\"x8\"",
}, {
    original: image6,
    originalTitle: "phase distortion",
    originalDescription: "graphite and gouache on paper. 9\"x12\"",
}, {
    original: image7,
    originalTitle: "pulse wave",
    originalDescription: "toner and gouache on paper. 9\"x12\"",
}, {
    original: image8,
    originalTitle: "quantize",
    originalDescription: "​graphite and watercolor on paper. 8\"x8\"",
}, {
    original: image9,
    originalTitle: "sync/drift",
    originalDescription: "​acrylic and graphite on paper. 9\"x12\"",
}, {
    original: image10,
    originalTitle: "terminal",
    originalDescription: "​acrylic on yupo. 9\"x12\"",
}, {
    original: image11,
    originalTitle: "video logic",
    originalDescription: "​acrylic and graphite on paper. 9\"x12\"",
}, {
    original: image12,
    originalTitle: "zone",
    originalDescription: "​acrylic and graphite on paper. 9\"x12\"",
}, {
    original: image13,
    originalTitle: "tyd",
    originalDescription: "​acrylic, watercolor and graphite on paper. 16\"x20\"",
}, {
    original: image14,
    originalTitle: "discontinuity",
    originalDescription: "colored pencil and watercolor on paper. 10\"x10\"",
}];

export default images;