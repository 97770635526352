import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Gallery from '../components/Gallery';
import Page from '../components/Page';
import images from '../data/drawings';

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: '100%',
  },
  pointer: {
    cursor: 'pointer',
  }
}));

const Drawings = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(null);

  const handleOnClick = (index) => {
    setIndex(index);
    setOpen(true);
  }

  return !!images.length ? (
    <Page title="drawings">
      <Grid container spacing={3}>
        {images.map((image, index) => (
          <Grid item key={index} xs={12} sm={3} className={classes.pointer}>
            <img className={classes.responsive} src={image.original} alt="drawings" onClick={() => handleOnClick(index)} />
          </Grid>
        ))}
      </Grid>
      <Gallery
        open={open}
        onClose={() => setOpen(false)}
        index={index}
        images={images}
        fullScreen
      />
    </Page>
  ) : <LinearProgress />;
}

export default Drawings;
