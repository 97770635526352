import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveEmbed from 'react-responsive-embed';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: '100%',
  },
  video: {
    minWidth: '100%',
    padding: theme.spacing(1.5),
  }
}));

const SoundSystem = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <div className={classes.video}>
        <ResponsiveEmbed
          src="https://player.vimeo.com/video/124731474?title=0&byline=0&portrait=0"
          allowFullScreen
        />
      </div>
      <Grid item xs={12} sm={5}>
        <List disablePadding>
          <ListItem dense>
            <ListItemText primary="Year" secondary="2013" />
          </ListItem>
          <ListItem dense>
            <ListItemText
              primary="Medium"
              secondary="4 channel video and sound"
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} sm={7}>
        <p>
          A four channel audio video projection created with an
          analog modular audio/video synthesizer. This piece is a navigation
          through the space between synthetically generated audio and video,
          feeding each back into the other to create “structures” that are
          constantly on the edge of destabilization and chaos.
        </p>
      </Grid>
    </Grid>
  )
}

export default SoundSystem;
