import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import image1 from '../assets/pod-1.jpg';
import image2 from '../assets/pod-2.jpg';
import image3 from '../assets/pod-3.jpg';
import image4 from '../assets/pod-4.jpg';
import image5 from '../assets/pod-5.jpg';

const images = [
  image3,
  image4,
  image5,
];

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: '100%',
  },
  quote: {
    margin: 0,
    color: theme.palette.text.secondary,
  },
  video: {
    minWidth: '100%',
    padding: theme.spacing(1.5),
  }
}));

const DrawingPod = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item>
        <img className={classes.responsive} src={image1} alt="drawing-pod" />
      </Grid>
      <Grid item>
        <img className={classes.responsive} src={image2} alt="drawing-pod" />
      </Grid>
      <Grid item xs={12} sm={5}>
        <List disablePadding>
          <ListItem dense>
            <ListItemText primary="Year" secondary="2013" />
          </ListItem>
          <ListItem dense>
            <ListItemText
              primary="Medium"
              secondary="plywood, electronics, software"
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} sm={7}>
        <p>Duophonic drawing pod is partnership with Site Space mobile residency. It is a 12′ long camping trailer that has been repurposed into a mobile project space, creating an immersive multi-sensory experience that is controlled and manipulated through simple means of drawing and mark-making. In recording, altering and playing back the sounds the participants create through drawing or writing. They are put into an altered relationship with something that they are so accustomed to hearing that they usually tune it out.</p>
        <p>Once the sound has been foregrounded and their experience of it has shifted, something that was previously tangential and ephemeral is given a new significance. With this new aural awareness, they begin to make marks with the focus on how those marks produce different sounds and the way they made those marks changed the characteristics of the resultant sounds along with the visual components.</p>
      </Grid>
      {images.map((image, index) => (
        <Grid item key={index}>
          <img className={classes.responsive} src={image} alt="drawing-pod" />
        </Grid>
      ))}
    </Grid>
  )
}

export default DrawingPod;
