import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Gallery from '../components/Gallery';
import image1 from '../assets/projects-6.jpg';
import audio from '../assets/pattern001.mp3';
import images from '../data/patternOne';

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: '100%',
  },
  gridListRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  tile: {
    cursor: 'pointer',
  },
  full: {
    width: '100%',
    height: '100%'
  },
}));

const PatternOne = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(null);

  const handleOnClick = (index) => {
    setIndex(index);
    setOpen(true);
  }

  return !!images.length ? (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <div className={classes.gridListRoot}>
            <GridList cellHeight="auto" cols={4}>
              {images.map((tile, index) => (
                <GridListTile
                  key={`${tile.original}-${index}`}
                  cols={1}
                  classes={{ imgFullWidth: classes.full, imgFullHeight: classes.full }}
                  className={classes.tile}
                >
                  <img src={tile.original} alt={tile.originalTitle} onClick={() => handleOnClick(index)} />
                </GridListTile>
              ))}
            </GridList>
          </div>
        </Grid>
        <Grid item xs={12}>
          <audio controls>
            <source
              src={audio}
              type="audio/mpeg"
            />
          </audio>
        </Grid>
        <Grid item xs={12} sm={5}>
          <List disablePadding>
            <ListItem dense>
              <ListItemText primary="Year" secondary="2012" />
            </ListItem>
            <ListItem dense>
              <ListItemText
                primary="Medium"
                secondary="Digital Print and Gouache, Screen Print on Panel, Electronics"
              />
            </ListItem>
            <ListItem dense>
              <ListItemText
                primary="Panel Sizes"
                secondary="13 x 13 x 2 inches"
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={7}>
          <p>A suite of 16 panels with audio electronics mounted behind them utilizing the combination and interaction of different methods of logic and rules based systems. These concepts of logic and systems are drawn from various sources such as complex modern financial structures, primitive fractals, experimental sound processing/sequencing and compression algorithms used in digital media.</p>
          <p>The combination of these various structures plays with ideas found in the cognitive sciences that have long interested me, pattern seeking and phenomenology especially.</p>
          <p>Taking components from these various forms of logic and playing with them is both my attempt to investigate what components of these structures interests me and to allow space for them to transform, interfere and re-inform one another through their translation into components of drawings and sound pieces.</p>
        </Grid>
        <Grid item>
          <img className={classes.responsive} src={image1} alt="pattern" />
        </Grid>
      </Grid>
      <Gallery
        open={open}
        onClose={() => setOpen(false)}
        index={index}
        images={images}
        subheading={`inkjet and gouache on panel / 2012 / 13" x 13"`}
      />
    </>
  ) : null;
}

export default PatternOne;
