import React from 'react';
import trim from 'lodash/trim';
import { Redirect, useParams, Switch, Route } from 'react-router-dom';
import Page from '../components/Page';
import projectsData from '../data/projectsData';
import Breath from './Breath';
import DrawingDesk from './DrawingDesk';
import DrawingPod from './DrawingPod';
import SoundSystem from './SoundSystem';
import MagicalMistakes from './MagicalMistakes';
import PatternOne from './PatternOne';
import PatternTwo from './PatternTwo';
import Riso from './Riso';

const Projects = () => {
  const { id } = useParams();
  const project = projectsData.find(d => trim(d.path, '/') === id);

  if (!project) {
    return (
      <Redirect push={false} to="/projects" />
    )
  }

  return (
    <Page title={project.title}>
      <Switch>
        <Route path="/projects/breath">
          <Breath />
        </Route>
        <Route path="/projects/drawing-desk">
          <DrawingDesk />
        </Route>
        <Route path="/projects/duophonic-drawing-pod">
          <DrawingPod />
        </Route>
        <Route path="/projects/sound-system">
          <SoundSystem />
        </Route>
        <Route path="/projects/magical-mistakes">
          <MagicalMistakes />
        </Route>
        <Route path="/projects/patternicity-001">
          <PatternOne />
        </Route>
        <Route path="/projects/patternicity-002">
          <PatternTwo />
        </Route>
        <Route path="/projects/riso">
          <Riso />
        </Route>
      </Switch>
    </Page>
  );
}

export default Projects;
