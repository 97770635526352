import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Page from '../components/Page';
import image1 from '../assets/iced-out.jpg';
import image2 from '../assets/Glyph_Logo_png.png';

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: '100%',
  },
}));

const About = () => {
  const classes = useStyles();

  return (
    <Page title="biography">
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <p>Rob Brown is a visual and sound artist who
          lives in Costa Mesa, California. He was born in 1981
          in Joplin, Missouri and grew up in Reno, Nevada.
          His practice includes drawing, printmaking, painting
          and audio/video installation. His current work deals with ideas
          of systems, structure, logic and perception. He employs
          processed and synthesized sounds alongside prints and drawings.
          These works are realized through a set of self-defined parameters
          which come from personal research and interests.</p>

          <p>His interests span from experimental sound synthesis
          and generative sequencing to cognitive science and both
          colloquial/formal ideas of logic. His aim is to create an
          opportunity for the viewer to enter a more active viewing
          and listening space. He believes there is value in the act
          of slowing down and spending time with something in a society
          that is increasingly surrounded and bombarded with stimuli designed
          to be digested in split seconds.</p>

          <p>He received his AAA in Animation Art and Design from the
          Seattle Art Institute in 2002 and his BFA in painting and drawing
          from the University of Nevada, Reno in 2008. In spring of 2013
          he received his MFA in studio art from California State University,
          Long Beach. He has exhibited his work nationally and abroad.</p>
        </Grid>
        <Grid item xs={12} md={5}>
          <img className={classes.responsive} src={image1} alt="iced-out" />
          <Typography>Rob Brown</Typography>
          <Link
            href="https://instagram.com/rawblades/"
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            <img src={image2} alt="instagram-logo" width={24} />
          </Link>
        </Grid>
      </Grid>
    </Page>
  );
}

export default About;
