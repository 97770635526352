import image1 from '../assets/pattern-1.jpg';
import image2 from '../assets/pattern-2.jpg';
import image3 from '../assets/pattern-3.jpg';
import image4 from '../assets/pattern-4.jpg';
import image5 from '../assets/pattern-5.jpg';
import image6 from '../assets/pattern-6.jpg';
import image7 from '../assets/pattern-7.jpg';
import image8 from '../assets/pattern-8.jpg';
import image9 from '../assets/pattern-9.jpg';
import image10 from '../assets/pattern-10.jpg';

const images = [
  {
      original: image1,
      originalTitle: 'one',
  },
  {
      original: image2,
      originalTitle: 'two',
  },
  {
      original: image3,
      originalTitle: 'three',
  },
  {
      original: image4,
      originalTitle: 'four',
  },
  {
      original: image5,
      originalTitle: 'five',
  },
  {
      original: image6,
      originalTitle: 'six',
  },
  {
      original: image7,
      originalTitle: 'seven',
  },
  {
      original: image8,
      originalTitle: 'eight',
  },
  {
      original: image9,
      originalTitle: 'nine',
  },
  {
      original: image10,
      originalTitle: 'ten',
  }
];

export default images;