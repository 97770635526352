import React from 'react';
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import ProjectsMenu from './Pages/ProjectsMenu';
import Projects from './Pages/Projects';
import About from './Pages/About';
import CV from './Pages/CV';
import Drawings from './Pages/Drawings';
import Content from './components/Content';

const App = () => {
  return (
    <Router>
      <Content>
        <Switch>
          <Route path="/drawings" exact><Drawings /></Route>
          <Route path="/cv" exact><CV /></Route>
          <Route path="/about" exact><About /></Route>
          <Route path="/projects" exact><ProjectsMenu /></Route>
          <Route path="/projects/:id"><Projects /></Route>
          <Redirect from="/" to="/projects" exact />
        </Switch>
      </Content>
    </Router>
  );
}

export default App;
