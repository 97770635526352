import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Page from '../components/Page';

const useStyles = makeStyles((theme) => ({
  year: {
    whiteSpace: 'noWrap',
    fontWeight: 'bold',
    paddingLeft: 0,
  },
  section: {
    fontWeight: 'bold',
    paddingLeft: 0,
    paddingTop: theme.spacing(3)
  },
}));

const CV = () => {
  const classes = useStyles();

  return (
    <Page title="curriculum vitae">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.section} colSpan={2}>EDUCATION</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2009-2013</TableCell>
          <TableCell>Masters of Fine Arts, California State University Long Beach</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2003-2008</TableCell>
            <TableCell>Bachelor of Fine Art, University of Nevada Reno</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2000-2002</TableCell>
            <TableCell>Associates of Applied Arts in Animation Art and Design, Art Institute of Seattle</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.section} colSpan={2}>SOLO, AND 2-PERSON EXHIBITIONS</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2018</TableCell>
            <TableCell>“Sermonette” with Jorge Mujica, Actual Size, Los Angeles, CA</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2017</TableCell>
            <TableCell>“A Vision of Standing Rock Under San Pedro” with Jorge Mujica, C.A.C.T.t.U.S. , Long Beach CA</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2015</TableCell>
            <TableCell>“Structure Synthesis” with Melissa Manfull, Irvine Fine Arts Center, Irvine CA</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2014</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Emergent Forms” with Nadege Monchera Baer, C Feign Junior, Los Angeles, CA</ListItem>
                <ListItem dense disableGutters>“breath” with Patrick Faulk, Duff Gallery, Cal State Fullerton, CA</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2013</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“mark/sequence, mfa thesis exhibition” Werby Gallery, Cal State Long Beach, CA</ListItem>
                <ListItem dense disableGutters>“Chopped and Screwed” with Dane Klingaman and Gabriel Garcia DR. Maxine Merlino Gallery, Cal State Long Beach, CA</ListItem>
                <ListItem dense disableGutters>“verge" with Camilla Taylor, USU Gallery, Cal State Long Beach, CA</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2012</TableCell>
            <TableCell>“Apogee” with Tyler Ferreira and Camilla Taylor DDR Projects, Santa Ana, CA</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2007</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“unclean volume two: a bfa thesis exhibition” with Nick Larsen, Never Ender     Gallery, Reno, Nv</ListItem>
                <ListItem dense disableGutters>“unclean volume one” with Nick Larsen, Holland Arts and Music Initiative, Reno, NV</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2006</TableCell>
            <TableCell>“BFA Midway Exhibition” McNamara Gallery, University of Nevada, Reno, NV</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2005</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Feelers” With Ahren Hertel, Sierra Nevada College of the Arts, Incline Village, NV</ListItem>
                <ListItem dense disableGutters>“copy er” McNamara Gallery, University of Nevada Reno, Reno, NV</ListItem>
                <ListItem dense disableGutters>“I don’t think you paint nearly enough” Never Ender Gallery, Reno,  NV</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.section} colSpan={2}>Group Exhhibitions</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2021</TableCell>
            <TableCell>“Highbeams 3: Lazer Snake” Tiger Strikes Asteroid, LA</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2021</TableCell>
            <TableCell>“VDPII” Gatakers Artspace, Maryborough, Australia</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2019</TableCell>
            <TableCell>“Concordia University Faculty Show” Concordia University, Irvine, CA</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2017</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“PUMP” Ice House, Long Beach, CA</ListItem>
                <ListItem dense disableGutters>“Cortina de Nopales” Basement Projects, Santa Ana, CA</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2016</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Tool Crib” Rio Honda College, Whittier, CA</ListItem>
                <ListItem dense disableGutters>
                  “Vie Du Pacifique II Pacific Perimeter” Impress Printmakers Gallery, Wooloowin, Australia
                </ListItem>
                <ListItem dense disableGutters>“Tracing Tomorrow” Collective Arts Incubator, Los Angeles, CA</ListItem>
                <ListItem dense disableGutters>“Dirt Making” Winning/Losing, Los Angeles, CA</ListItem>
                <ListItem dense disableGutters>“Biannual Valentines Invitational” Sheppard Gallery, University of Nevada Reno, NV</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2015</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Mutual Aid” Biola University Library, La Mirada, CA</ListItem>
                <ListItem dense disableGutters>“New Moon” The Yurt, San Clemente, CA</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2014</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“All In” Holland Project, Reno, NV</ListItem>
                <ListItem dense disableGutters>“Now and Then” Dutzi Gallery, Cal State Long Beach, CA</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2013</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“48x14” Studio 11, Los Angeles, CA</ListItem>
                <ListItem dense disableGutters>“Little Games” Long Beach Art Exchange, Long Beach, CA</ListItem>
                <ListItem dense disableGutters>“A New Space” Hibbleton Gallery, Fullerton, CA</ListItem>
                <ListItem dense disableGutters>“Insights 2013: The Annual Student Art Exhibition” University Art Museum, Cal State Long Beach, CA</ListItem>
                <ListItem dense disableGutters>"Soundwalk 2013"  Downtown Long Beach, CA</ListItem>
                <ListItem dense disableGutters>“sound/systems” Werby Gallery, Cal State Long Beach, CA</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2012</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Californians” CU Gallery, Degu, Korea</ListItem>
                <ListItem dense disableGutters>“Insights 2012: The Annual Student Art Exhibition” University Art Museum, Cal State Long Beach, CA</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2011</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Dwell” Grand Central Art Center Sales Gallery, Santa Ana, CA</ListItem>
                <ListItem dense disableGutters>“Insights 2011: The Annual Student Art Exhibition” University Art Museum, Cal State Long Beach, CA</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2010</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Significantly Other” Sierra Arts, Reno, Nv</ListItem>
                <ListItem dense disableGutters>“INK 5: Animals” Dutzi Gallery, Cal State Long Beach, CA</ListItem>
                <ListItem dense disableGutters>“Quotidian Encounters” Dutzi Gallery,  Cal State Long Beach, CA</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2009</TableCell>
            <TableCell>“Artist’s for the Betterment of Society: Public Poster Project” Reno Events Center, Reno, NV</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2008</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Facial Paradox” Sierra Arts Northwest Library Gallery, Reno, NV</ListItem>
                <ListItem dense disableGutters>“Lithography: From Stone to Digital” Southern Graphics Print Conference, Richmond, VA</ListItem>
                <ListItem dense disableGutters>“Biannual Valentines Invitational”  Sheppard Gallery, University of Nevada Reno</ListItem>
                <ListItem dense disableGutters>“Summer Show” Plumas House, Historical Landmark. Reno, NV</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2007</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Reno-Tahoe Young Professional Network, Launch Party,” Curated by Ann Wolfe, Curator, Nevada Museum of Art, Reno, NV</ListItem>
                <ListItem dense disableGutters>“(Con)temporary Gallery: Con-clusion”, Public Mural Installation, Reno, NV</ListItem>
                <ListItem dense disableGutters>“Hell Would be Worth It” The Arts Factory. CAC Las Vegas, NV</ListItem>
                <ListItem dense disableGutters>“I am Elephant: A Sleepy Time Collaborative Show” Grayspace, Reno, NV</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2006</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Reno River Festival Urban Art Mural” Nightingale Rooftop Gallery, Nevada Museum of Art, Reno, NV</ListItem>
                <ListItem dense disableGutters>“2006 Endowment Grant Recipients Display” Sierra Arts Gallery, Reno, NV</ListItem>
                <ListItem dense disableGutters>“(Con)temporary Gallery”, Public Mural Installation, Reno, NV</ListItem>
                <ListItem dense disableGutters>“Peripheral Vision” Sheppard Gallery, University of Nevada Reno</ListItem>
                <ListItem dense disableGutters>“Annual Juried Student Show” Sheppard Gallery, University of Nevada Reno</ListItem>
                <ListItem dense disableGutters>“Biannual Valentines Invitational”  Sheppard Gallery, University of Nevada Reno</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2005</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Buff Culture” Front Door Gallery, University of Nevada Reno, Reno, NV </ListItem>
                <ListItem dense disableGutters>“Nostalgia”  Never Ender Gallery, Reno, NV</ListItem>
                <ListItem dense disableGutters>“Art In Public Places” Jot Travis Student Union, University of Nevada Reno</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2004</TableCell>
            <TableCell>“November Coming Hellfire” Chapterhouse Gallery, Reno, NV</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.section} colSpan={2}>AWARDS AND SCHOLARSHIPS</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2010</TableCell>
            <TableCell>Max Gatov Graduate Studies Merit Award, California State   University Long  Beach</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2007</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>Department of Art Merit Scholarship, University of Nevada Reno</ListItem>
                <ListItem dense disableGutters>Department of Art Student Travel Scholarship: University of Nevada, Reno Department of Art Student Exchange with the University of Huddersfield, England</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2006</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>Received Sierra Arts Foundation’s Annual Endowment Grant</ListItem>
                <ListItem dense disableGutters>Won juror prize for work in University of Nevada Reno Annual Juried Student Show</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.section} colSpan={2}>RESIDENCIES</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2006</TableCell>
            <TableCell>Artist in Residence Nevada Museum of Art, Mural Installation, Nov 3-13, Reno, NV</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.section} colSpan={2}>PORTFOLIOS</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2021</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Vie Du Pacifique III Pacific Perimeter”</ListItem>
                <ListItem dense disableGutters>Organizers: Jenny Sanzaro – Nishimura and Jennifer Stuerzl</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2016</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Vie Du Pacifique II Pacific Perimeter”</ListItem>
                <ListItem dense disableGutters>Organizers: Jenny Sanzaro – Nishimura and Jennifer Stuerzl</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2014</TableCell>
            <TableCell>
              “Now and Then”, portfolio exchange between La Sierra and Cal State Long Beach University Graduate students and alumni
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2008</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>Lithography - From Stone to Digital, portfolio exchange for SGC Conference, Richmond, VA</ListItem>
                <ListItem dense disableGutters>Organizer: Eunkang Koh</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.section} colSpan={2}>TEACHING AND ASSISTANTSHIPS</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Spring 2021</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>Elements of Art, Art 200, Concordia University Irvine</ListItem>
                <ListItem dense disableGutters>Screen Printing 1&2 Art 175/176, Orange Coast College</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Fall 2020</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>Photo/digital Printmaking Art 475/575, Cal State University Long Beach</ListItem>
                <ListItem dense disableGutters>Screen Printing 1&2 Art 175/176, Orange Coast College</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Spring 2020</TableCell>
            <TableCell>Screen Printing 1&2 Art 175/176, Orange Coast College</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Fall 2019</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>Photo/digital Printmaking Art 475/575, Cal State University Long Beach</ListItem>
                <ListItem dense disableGutters>Screen Printing 1&2 Art 175/176, Orange Coast College</ListItem>
                <ListItem dense disableGutters>Printmaking 1 Art 351, Concordia University Irvine</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Spring 2019</TableCell>
            <TableCell>Screen Printing 1&2 Art 175/176, Orange Coast College	</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Fall 2018</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>Photo/digital Printmaking Art 475/575, Cal State University Long Beach</ListItem>
                <ListItem dense disableGutters>Screen Printing 1&2 Art 175/176, Orange Coast College</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Spring 2018</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>Printmaking 1&2 Art 351/451, Concordia University Irvine</ListItem>
                <ListItem dense disableGutters>Screen Printing 1&2 Art 175/176, Orange Coast College</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Fall 2017</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>Photo/digital Printmaking Art 475/575, Cal State University Long Beach</ListItem>
                <ListItem dense disableGutters>Screen Printing 1&2 Art 175/176, Orange Coast College</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Summer 2016</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>Young Adult Art Studio Printmaking Section, Cal State University </ListItem>
                <ListItem dense disableGutters>Long Beach (Age 15-18)</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Summer 2015</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>Young Adult Art Studio Printmaking Section, Cal State University </ListItem>
                <ListItem dense disableGutters>Long Beach (Age 15-18)</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Spring 2015</TableCell>
            <TableCell>Intro to Digital Art Art 149, Cal State University Long Beach (Substitute, 5 weeks)</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Summer 2014</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>Young Adult Art Studio Printmaking Section, Cal State University </ListItem>
                <ListItem dense disableGutters>Long Beach (Age 15-18)</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Fall 2014</TableCell>
            <TableCell>Photo/digital Printmaking, Cal State Long Beach</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Spring 2014</TableCell>
            <TableCell>Photo/digital Printmaking, Cal State Long Beach</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Summer 2014</TableCell>
            <TableCell>Young Adults Art Camp Printmaking, Cal State Long Beach</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Spring 2013</TableCell>
            <TableCell>Printmaking Survey with Roxane Sexauer, Cal State Long Beach</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Spring 2008</TableCell>
            <TableCell>Introduction to Printmaking with Eunkang Koh, University of Nevada Reno</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Fall 2007</TableCell>
            <TableCell>Introduction to Printmaking with Eunkang Koh, University of Nevada Reno</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>Summer 2005</TableCell>
            <TableCell>Advanced Drawing with Mike Sarich, University of Nevada Reno </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.section} colSpan={2}>EXPERIENCE</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2010-present</TableCell>
            <TableCell>Lab Tech - Printmaking, Cal State Long Beach</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2014-present</TableCell>
            <TableCell>Contract printing and publishing Risograph prints, zines and books, Santa Ana, CA</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2015-2016</TableCell>
            <TableCell>The Build Shop Orange County, Shop Manager. Santa Ana, CA</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2015</TableCell>
            <TableCell>Zine Night Workshop in conjunction with Alien She Exhibition, Orange County Museum of Art, Newport Beach, CA</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2015</TableCell>
            <TableCell>Fanatic Friday Zine Workshop, Orange County Museum of Art, Newport Beach, CA</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2015</TableCell>
            <TableCell>Fanatic Friday Zine Workshop, Orange County Museum of Art, Newport Beach, CA</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2013</TableCell>
            <TableCell>200, 8 color, 2 sided screen printed cassette covers for Magical Mistakes</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2008</TableCell>
            <TableCell>9th Biennial Valentine Auction Exhibition, commissioned collaborative muralist</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2007</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>University of Nevada, Reno Department of Art Student Exchange with the University of Huddersfield, England.  March 17-28</ListItem>
                <ListItem dense disableGutters>Printer for artist Michael Sarich, University of Nevada, Reno Limited Edition Print Series</ListItem>
                <ListItem dense disableGutters>Printer for Ahren Hertel, Edition of 18 Hand Tinted Polyester Lithographs </ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.section} colSpan={2}>ARTIST LECTURES</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2019</TableCell>
            <TableCell>“Faculty Show Panel Lecture”, Concordia University Irvine, Ca</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2016</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Tool Crib” Rio Hondo College, Whittier, CA</ListItem>
                <ListItem dense disableGutters>“Y(OURS) Book Panel” Irvine Fine Arts Center, Irvine, CA</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2015</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Visiting Artist Lecture and BFA studio visits” Biola University, La Mirada, CA</ListItem>
                <ListItem dense disableGutters>“Living Room Talk” Palm Arts Complex, Orange County Great Park, Irvine, CA</ListItem>
                <ListItem dense disableGutters>“Senior Seminar, Visiting Artist Lecture” Biola University, La Mirada, CA</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2015</TableCell>
            <TableCell>"Weekly professional thursday lunch series” La Sierra University, Riverside, CA</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2006</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>Art Bite: Reno River Festival Urban Art Mural, Nevada Museum of Art, Reno, NV</ListItem>
                <ListItem dense disableGutters>Panel Discussion: Peripheral Vision, University of Nevada Reno</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.section} colSpan={2}>BIBLIOGRAPHY</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2008</TableCell>
            <TableCell>“Art and Soul,” R Life Magazine, April 2008, pg 19</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.year}>2006</TableCell>
            <TableCell>
              <List dense disablePadding>
                <ListItem dense disableGutters>“Pick up your heart’s desire at Valentine Auction,” Reno Gazette-Journal, 2-1-08</ListItem>
                <ListItem dense disableGutters> Featured artist in Reno Gazette Journal Calendar for Biannual Valentines Invitational at University of Nevada Reno</ListItem>
              </List>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Page>
  );
}

export default CV;
