import React from 'react';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import ImageGallery from 'react-image-gallery';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  flexGrow: {
    flex: 1,
  }
}));

const Gallery = ({ open, onClose, index, images = [], subheading, fullScreen, ...props }) => {
  const classes = useStyles();
  const [imageIndex, setImageIndex] = React.useState(index);
  const title = get(images, `[${imageIndex}].originalTitle`, '');
  const description = get(images, `[${imageIndex}].originalDescription`, subheading)

  const handleOnBeforeSlide = (nextIndex) => {
    setImageIndex(nextIndex);
  }

  React.useEffect(() => {
    if (index !== null) {
      setImageIndex(index);
    }
  }, [index]);

  return imageIndex !== null ? (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      {...props}
    >
      <DialogTitle disableTypography>
        <div className={classes.flex}>
          <div>
            <Typography
              variant="h6"
            >
              {title}
            </Typography>
            <Typography
              variant="caption"
            >
              {description}
            </Typography>
          </div>
          <div className={classes.flexGrow} />
          {fullScreen && (
            <IconButton
              color="inherit"
              onClick={onClose}
              aria-label="close"
              edge="start"
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>

      </DialogTitle>
      <DialogContent>
        <ImageGallery
          startIndex={imageIndex}
          items={images}
          showPlayButton={false}
          showFullscreenButton={false}
          showThumbnails={false}
          onBeforeSlide={handleOnBeforeSlide}
        />
      </DialogContent>
    </Dialog>
  ) : null;
}

export default Gallery;
