import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import image1 from '../assets/magical-1.jpg';
import image2 from '../assets/magical-2.jpg';
import image3 from '../assets/magical-3.jpg';
import image4 from '../assets/magical-4.jpg';
import image5 from '../assets/magical-5.jpg';

const images = [
  image2,
  image3,
  image4,
  image5,
];

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: '100%',
  },
}));

const MagicalMistakes = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item>
        <img className={classes.responsive} src={image1} alt="magical-mistakes" />
      </Grid>
      <Grid item xs={12} sm={5}>
        <List disablePadding>
          <ListItem dense>
            <ListItemText primary="Year" secondary="2013" />
          </ListItem>
          <ListItem dense>
            <ListItemText
              primary="Medium"
              secondary="screen printed cassette covers"
            />
          </ListItem>
          <ListItem dense>
            <ListItemText
              primary="Musical Group"
              secondary="Magical Mistakes"
            />
          </ListItem>
          <ListItem dense>
            <ListItemText
              primary="Music Label"
              secondary="Kill Quaniti"
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} sm={7}>
        <p>A seven layer screen print and design commissioned by San Diego based record label Kill Quanti for Osaka based musician Magical Mistakes. Hand printed edition of 200 with metallic and glow in the dark ink.</p>
      </Grid>
      {images.map((image, index) => (
        <Grid item key={index}>
          <img className={classes.responsive} src={image} alt="magical-mistakes" />
        </Grid>
      ))}
    </Grid>
  )
}

export default MagicalMistakes;
