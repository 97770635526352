import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/MailOutline';
import LaunchIcon from '@material-ui/icons/Launch';

const Navigation = ({ children }) => {
  const { pathname } = useLocation();
  const navItems = ['projects', 'drawings', 'about', 'cv'];

  return (
    <>
      <List>
        {navItems.map((text, index) => (
          <ListItem
            disableRipple
            button
            key={text}
            component={Link}
            to={`/${text}`}
            replace={pathname === `/${text}`}
          >
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemText primary="older works" />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              component="a"
              href="https://rawblades.tumblr.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LaunchIcon fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText primary="instagram" />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              component="a"
              href="https://instagram.com/rawblades/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LaunchIcon fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText primary="contact" />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              component="a"
              href="mailto:col_blahah@yahoo.com?subject=Contact from rallsbrown.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SendIcon fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
}

export default Navigation;
