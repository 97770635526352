import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Gallery from '../components/Gallery';
import image1 from '../assets/riso-1.jpg';
import image2 from '../assets/riso-2.jpg';
import image3 from '../assets/riso-3.jpg';
import image4 from '../assets/riso-4.jpg';
import image5 from '../assets/riso-5.jpg';
import image6 from '../assets/riso-6.jpg';
import image7 from '../assets/riso-7.jpg';
import image8 from '../assets/riso-8.jpg';
import image9 from '../assets/riso-9.jpg';
import image10 from '../assets/riso-10.jpg';
import image11 from '../assets/riso-11.jpg';
import image12 from '../assets/riso-12.jpg';
import image13 from '../assets/riso-13.jpg';
import image14 from '../assets/riso-14.jpg';
import image15 from '../assets/riso-15.jpg';

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image15,
];

const useStyles = makeStyles((theme) => ({
  responsive: {
    width: '100%',
  },
  pointer: {
    cursor: 'pointer',
  }
}));

const Riso = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(null);

  const handleOnClick = (index) => {
    setIndex(index);
    setOpen(true);
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <img className={classes.responsive} src={image14} alt="riso" />
        </Grid>
        <Grid item>
          <p>I added a Riso RP3700 to my life in Summer 2014, I’ve been running it out of my studio space Mutual Aid in downtown Santa Ana. If you are interested in Riso printing services please contact me for a quote.</p>
          <p>for examples of personal and commissioned projects feel free to browse my <Link href="https://instagram.com/rawblades/" target="_blank" rel="noopener" underlin="none">instagram feed.</Link></p>
        </Grid>
        {images.map((image, index) => index !== 7 ? (
          <Grid item key={index} xs={12} sm={3} className={classes.pointer}>
            <img className={classes.responsive} src={image} alt="riso" onClick={() => handleOnClick(index)} />
          </Grid>
        ) : (
          <Grid item xs={12} key={index} className={classes.pointer}>
            <img className={classes.responsive} src={image8} alt="riso" onClick={() => handleOnClick(7)} />
          </Grid>
        ))}

      </Grid>
      <Gallery
        open={open}
        onClose={() => setOpen(false)}
        index={index}
        images={images.map(image => ({ original: image }))}
        fullScreen
      />
    </>
  );
}

export default Riso;
